import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-4431124b"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["src"];
const _hoisted_2 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_icon = _resolveComponent("el-icon");
  const _component_custom_menu = _resolveComponent("custom-menu", true);
  const _component_el_sub_menu = _resolveComponent("el-sub-menu");
  const _component_el_menu_item = _resolveComponent("el-menu-item");
  return _openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.menus, item => {
    return _openBlock(), _createElementBlock(_Fragment, null, [item.children ? (_openBlock(), _createBlock(_component_el_sub_menu, {
      key: item.permissionId,
      index: item.permissionCode
    }, {
      title: _withCtx(() => {
        var _item$icon;
        return [item.icon && item.icon.startsWith('http') ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          class: "menu-image",
          src: item.icon
        }, null, 8, _hoisted_1)) : (_openBlock(), _createBlock(_component_el_icon, {
          key: 1,
          class: _normalizeClass(["iconfont", (_item$icon = item.icon) !== null && _item$icon !== void 0 ? _item$icon : $data.defaultIcon]),
          size: "18px"
        }, null, 8, ["class"])), _createElementVNode("span", null, _toDisplayString(item.permissionName), 1)];
      }),
      default: _withCtx(() => [_createVNode(_component_custom_menu, {
        menus: item.children,
        onMenuClick: _cache[0] || (_cache[0] = menu => this.$emit('menuClick', menu))
      }, null, 8, ["menus"])]),
      _: 2
    }, 1032, ["index"])) : (_openBlock(), _createBlock(_component_el_menu_item, {
      index: item.permissionCode,
      key: item.permissionId,
      onClick: () => this.$emit('menuClick', item)
    }, {
      title: _withCtx(() => [_createElementVNode("span", null, _toDisplayString(item.permissionName), 1)]),
      default: _withCtx(() => {
        var _item$icon2;
        return [item.icon && item.icon.startsWith('http') ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          class: "menu-image",
          src: item.icon
        }, null, 8, _hoisted_2)) : (_openBlock(), _createBlock(_component_el_icon, {
          key: 1,
          class: _normalizeClass(["iconfont", (_item$icon2 = item.icon) !== null && _item$icon2 !== void 0 ? _item$icon2 : $data.defaultIcon]),
          size: "18px"
        }, null, 8, ["class"]))];
      }),
      _: 2
    }, 1032, ["index", "onClick"]))], 64);
  }), 256);
}