export default {
  name: "custom-menu",
  emits: ['menuClick'],
  props: {
    menus: {
      type: Array
    }
  },
  data() {
    return {
      defaultIcon: 'icon-menu'
    };
  }
};