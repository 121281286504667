import CustomMenu from './custom-menu';
export default {
  name: " layout-aside",
  emits: ['menuClick'],
  components: {
    'custom-menu': CustomMenu
  },
  props: {
    collapse: {
      type: Boolean,
      default: false
    },
    menus: {
      type: Array
    }
  }
};