import "core-js/modules/es.array.push.js";
import "core-js/modules/es.error.cause.js";
import { mapGetters } from 'vuex';
import layoutHeader from './layout-header';
import layoutAside from './layout-aside';
import layoutMainTag from './layout-main-tag';
import zhCn from 'element-plus/lib/locale/lang/zh-cn';
import watermark from '@/plugins/watermark';
import { ElLoading, ElMessage, ElMessageBox, ElNotification } from 'element-plus';
import 'element-plus/es/components/loading/style/css'
import 'element-plus/es/components/message/style/css'
import 'element-plus/es/components/message-box/style/css'
import 'element-plus/es/components/notification/style/css'

import SockJS from 'sockjs-client';
import Stomp from 'stompjs';
export default {
  name: 'Layout2',
  components: {
    'layout-header': layoutHeader,
    'layout-aside': layoutAside,
    'layout-main-tag': layoutMainTag
  },
  computed: {
    ...mapGetters({
      pageOpenedList: 'page/getOpenedList',
      menus: 'perm/getMenus',
      userName: 'perm/getUserName'
    }),
    keepAliveCache: function () {
      let list = this.pageOpenedList;
      let caches = [];
      if (list && Array.isArray(list)) {
        list.forEach(function (page) {
          if (page.name) {
            caches.push(page.name);
          }
        });
      }
      return caches;
    }
  },
  data() {
    let validatePass1 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入确认密码'));
      } else if (value !== this.changePwdForm.newPwd) {
        callback(new Error('两次密码不匹配'));
      } else {
        callback();
      }
    };
    return {
      collapse: false,
      collapseAnimation: false,
      locale: zhCn,
      changePwdDialog: false,
      changePwdForm: {
        oldPwd: '',
        newPwd: '',
        newPwd1: ''
      },
      changePwdFormRules: {
        oldPwd: [{
          required: true,
          message: '请输入旧密码',
          trigger: 'blur'
        }, {
          min: 6,
          max: 18,
          message: '长度在 6 到 18 个字符',
          trigger: 'blur'
        }],
        newPwd: [{
          required: true,
          message: '请输入新密码',
          trigger: 'blur'
        }, {
          min: 8,
          max: 11,
          message: '长度在 8 到 11 个字符',
          trigger: 'blur'
        }],
        newPwd1: [{
          required: true,
          message: '请输入确认密码',
          trigger: 'blur'
        }, {
          min: 8,
          max: 11,
          message: '长度在 8 到 11 个字符',
          trigger: 'blur'
        }, {
          validator: validatePass1,
          trigger: 'blur'
        }]
      },
      currentMenuClick: false,
      // 用户点击了菜单，这样的话在layout-breadcrumb中不设置顶部菜单
      currentMenuId: null,
      currentMenus: [],
      taskCount: 0,
      taskList: [],
      taskListLoading: false,
      stompClient: null,
      stompClientConnectInterval: null,
      //连接检测
      time: null,
      //连接检测
      isFullScreenIng: false
    };
  },
  methods: {
    menuClick(page) {
      this.$refs.mainTag.menuClick(page);
    },
    collapseChange() {
      this.collapse = !this.collapse;
      this.collapseAnimation = true;
    },
    userInfoHandleCommand(command) {
      if (command === 'logout') {
        this.doLogout();
      } else if (command === 'changePwd') {
        this.changePwdDialog = true;
      }
    },
    doChangePwd() {
      this.$refs.pwdForm.validate(valid => {
        if (valid) {
          const loading = ElLoading.service();
          this.$api.uac.user.changePwd(this.changePwdForm.oldPwd, this.changePwdForm.newPwd).then(res => {
            if (res.code === 200) {
              this.changePwdDialog = false;
              ElMessage({
                message: res.message,
                type: 'success'
              });
            } else {
              ElMessage({
                message: res.message,
                type: 'warning'
              });
            }
          }).finally(() => {
            loading.close();
          });
        }
      });
    },
    doLogout() {
      ElMessageBox.confirm('确认退出吗?', '提示', {
        type: 'warning'
      }).then(() => {
        this.$api.logout().finally(() => {
          sessionStorage.removeItem('token');
          sessionStorage.removeItem('currentMenuId');
          // permissionCodes
          this.$store.dispatch('perm/setPermission', []);
          // menus
          this.$store.dispatch('perm/setMenus', []);
          // userName
          this.$store.dispatch('perm/setUserName', '');
          this.$router.replace('/login');
        });
      });
    },
    help() {},
    changeToMenu(menu, currentMenuClick) {
      if (menu) {
        if (menu.permissionCode.indexOf(".html") > 0) {
          window.open(menu.permissionCode, '_self');
        } else if (menu.children && menu.children.length > 0) {
          this.currentMenuId = menu.permissionId;
          this.currentMenus = menu.children;
          this.currentMenuClick = currentMenuClick;
          sessionStorage.setItem('currentMenuId', this.currentMenuId);
        } else {
          this.$router.push(menu.permissionCode);
        }
      }
    },
    loadTask() {
      this.taskListLoading = true;
      this.$api.flow.flowApi.myTaskList({
        page: 1,
        size: 10
      }).then(res => {
        if (res.code === 200) {
          this.taskList = res.data.records;
        }
      }).finally(() => {
        this.taskListLoading = false;
      });
    },
    loadTaskCount() {
      this.$api.flow.flowApi.taskCount().then(res => {
        if (res.code === 200) {
          this.taskCount = res.data;
        }
      });
    },
    stompConnect() {
      if (this.stompClient == null || !this.stompClient.connected) {
        this.stompClient = null;
        let token = sessionStorage.getItem('token');
        let socket = new SockJS(`${process.env.VUE_APP_API_URL}/message/ws?token=${token}`, null, {
          transports: ['websocket']
        });
        this.stompClient = Stomp.over(socket);
        this.stompClient.debug = null;
        this.stompClient.connect({}, frame => {
          if (frame && frame.command === 'CONNECTED') {
            this.stompClient.subscribe('/user/topic/stompMessage', response => {
              let data = JSON.parse(response.body);
              if (data.type === 'dataImport') {
                let status = data.status;
                let importType = data.importType; // 导入类型
                let createTime = data.createTime;
                let fileName = data.fileName;
                if (status === '导入成功') {
                  ElNotification({
                    title: status,
                    message: `您于${createTime}上传的${importType}文件《${fileName}》已经处理成功`,
                    type: 'success'
                  });
                } else if (status === '导入失败') {
                  let message = data.message;
                  let errorFilePath = data.errorFilePath;
                  if (errorFilePath) {
                    ElNotification({
                      title: status,
                      dangerouslyUseHTMLString: true,
                      message: `<span>您于${createTime}上传的${importType}文件《${fileName}》处理失败。</span><a target="_blank" href="${errorFilePath}">下载错误文件</a>`,
                      duration: 15000,
                      type: 'error'
                    });
                  } else {
                    ElNotification({
                      title: status,
                      message: `您于${createTime}上传的${importType}文件《${fileName}》处理失败。失败原因：${message}`,
                      duration: 15000,
                      type: 'error'
                    });
                  }
                } else if (status === '处理中') {
                  ElNotification({
                    title: status,
                    message: `您于${createTime}上传的${importType}文件《${fileName}》开始处理`,
                    type: 'info'
                  });
                }
              }
            });
          }
        }, error => {
          console.log(error);
        });
      }
    },
    fullScreen() {
      if (!this.isFullScreenIng) {
        this.requestFullscreen();
      } else {
        this.exitFullscreen();
      }
      this.isFullScreenIng = !this.isFullScreenIng;
    },
    requestFullscreen() {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      }
    },
    exitFullscreen() {
      if (this.isFullScreenIng) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        }
      }
    }
  },
  mounted() {
    watermark.set(this.userName, this.productName);
    let currentMenuId = sessionStorage.getItem('currentMenuId');
    if (currentMenuId) {
      console.log('currentMenuId', currentMenuId);
      this.menus.forEach(item => {
        if (item.permissionId === currentMenuId) {
          this.changeToMenu(item);
        }
      });
    } else if (this.menus) {
      this.changeToMenu(this.menus[0]);
    }
    this.loadTaskCount();
    this.stompConnect();
    this.stompClientConnectInterval = setInterval(() => {
      let token = sessionStorage.getItem('token');
      if (!token) {
        clearInterval(this.stompClientConnectInterval);
      }
      if (this.stompClient != null && !this.stompClient.connected) {
        // 重连
        this.stompConnect();
      }
    }, 10000);
    this.time = setInterval(() => {
      this.loadTaskCount();
    }, 10000);
    // credentialsExpiredDay
    let credentialsExpiredDay = sessionStorage.getItem('credentialsExpiredDay');
    if (credentialsExpiredDay) {
      sessionStorage.removeItem('credentialsExpiredDay');
      this.$nextTick(() => {
        ElNotification({
          title: '密码即将过期',
          message: `您的密码将于${credentialsExpiredDay}日后过期，请及时修改`,
          type: 'warning',
          duration: 0
        });
      });
    }
    this.exitFullscreen();
    this.isFullScreenIng = !(document.fullscreenEnabled || document.mozFullScreenEnabled || document.webkitFullscreenEnabled);
  },
  beforeUnmount() {
    if (this.stompClient && this.stompClient.connected) {
      this.stompClient.disconnect();
    }
    if (this.stompClientConnectInterval) {
      clearInterval(this.stompClientConnectInterval);
    }
    if (this.time) {
      clearInterval(this.time);
    }
    watermark.close();
  }
};