import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-a26f7084"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "header"
};
const _hoisted_2 = {
  class: "nav-wrap"
};
const _hoisted_3 = {
  class: "nav"
};
const _hoisted_4 = {
  class: "header-center"
};
const _hoisted_5 = {
  class: "header-right"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_icon = _resolveComponent("el-icon");
  const _component_el_row = _resolveComponent("el-row");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_row, {
    class: "header-left"
  }, {
    default: _withCtx(() => [_createElementVNode("div", {
      class: _normalizeClass(["logo-wrap", {
        'logo-wrap-collapse': $props.collapse
      }])
    }, [_renderSlot(_ctx.$slots, "headerLogo", {}, undefined, true)], 2), _createElementVNode("div", _hoisted_2, [_createElementVNode("ul", _hoisted_3, [_createElementVNode("li", null, [_createElementVNode("div", {
      class: "nav-item",
      onClick: _cache[0] || (_cache[0] = (...args) => $options.doCollapse && $options.doCollapse(...args))
    }, [_createVNode(_component_el_icon, {
      size: 18,
      class: _normalizeClass(["iconfont", {
        'icon-indent': $props.collapse,
        'icon-outdent': !$props.collapse
      }])
    }, null, 8, ["class"])])])])])]),
    _: 3
  }), _createElementVNode("div", _hoisted_4, [_renderSlot(_ctx.$slots, "headerCenter", {}, undefined, true)]), _createElementVNode("div", _hoisted_5, [_renderSlot(_ctx.$slots, "headerRight", {}, undefined, true)])]);
}