import "core-js/modules/es.array.push.js";
import { mapGetters } from "vuex";
import { ElMessage } from "element-plus";
import 'element-plus/es/components/message/style/css'

export default {
  name: "layout-main-tag",
  computed: {
    ...mapGetters({
      pageOpenedList: 'page/getOpenedList',
      currentPage: 'page/getCurrentPage'
    })
  },
  watch: {
    rightClickMenuVisible(value) {
      if (value) {
        document.body.addEventListener('click', this.closeRightClickMenu);
      } else {
        document.body.removeEventListener('click', this.closeRightClickMenu);
      }
    }
  },
  data() {
    return {
      rightClickMenuVisible: false,
      rightClickMenuLeft: 0,
      rightClickMenuTop: 0,
      selectedPage: {}
    };
  },
  methods: {
    menuClick(page) {
      let openedPageLen = this.pageOpenedList.length;
      let tagHasOpened = false;
      for (let i = 0; i < openedPageLen; i++) {
        if (page.permissionCode === this.pageOpenedList[i].path) {
          tagHasOpened = true;
          break;
        }
      }
      if (!tagHasOpened && openedPageLen >= 10) {
        ElMessage.error('不能打开超过10个页面');
      } else {
        sessionStorage.setItem('menuClick', 'Y');
        this.$router.push(page.permissionCode);
      }
      // page.closable = true;
      // if (tagHasOpened) { // 已经打开，直接路由
      //     this.$store.dispatch('page/setCurrentPage', page);
      //     this.$router.push({
      //         path: page.permissionCode,
      //         query: options
      //     });
      // } else if (openedPageLen > 10) {// 默认包含首页
      //     ElMessage.error('不能打开超过10个页面');
      // } else {
      //     this.$store.dispatch('page/addOpenedPage', page);
      //     this.$store.dispatch('page/setCurrentPage', page);
      //     this.$router.push({
      //         path: page.permissionCode,
      //         query: options
      //     });
      // }
    },

    tagClick(page) {
      this.$store.dispatch('page/setCurrentPage', page);
      sessionStorage.setItem('menuClick', 'Y');
      this.$router.push(page.path);
    },
    tagClose(page) {
      let scope = this;
      this.pageOpenedList.map((item, index) => {
        if (item.path === page.path) {
          let beforePage = scope.pageOpenedList[index - 1];
          if (scope.currentPage.path === page.path && beforePage) {
            scope.$store.dispatch('page/setCurrentPage', beforePage);
            sessionStorage.setItem('menuClick', 'Y');
            scope.$router.push(beforePage.path);
          }
          scope.$store.dispatch('page/closePage', page.path);
        }
      });
    },
    closeAllPage() {
      this.$store.dispatch('page/clearAllPage');
      this.$store.dispatch('page/setCurrentPage', this.pageOpenedList[0]);
      sessionStorage.setItem('menuClick', 'Y');
      this.$router.push(this.pageOpenedList[0].path);
    },
    closeOthersPage() {
      this.$store.dispatch('page/setCurrentPage', this.selectedPage);
      sessionStorage.setItem('menuClick', 'Y');
      this.$router.push(this.selectedPage.path);
      this.$store.dispatch('page/clearOtherPage');
    },
    openRightClickMenu(page, event) {
      this.rightClickMenuLeft = event.clientX + 15;
      this.rightClickMenuTop = event.clientY;
      this.selectedPage = page;
      this.rightClickMenuVisible = true;
    },
    closeRightClickMenu() {
      this.rightClickMenuVisible = false;
    }
  }
};