import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_custom_menu = _resolveComponent("custom-menu");
  const _component_el_menu = _resolveComponent("el-menu");
  return _openBlock(), _createBlock(_component_el_menu, {
    "default-active": _ctx.$route.meta && _ctx.$route.meta.parent ? _ctx.$route.meta.parent : _ctx.$route.path,
    collapse: $props.collapse,
    class: "aside-scrollbar-menu",
    "unique-opened": ""
  }, {
    default: _withCtx(() => [_createVNode(_component_custom_menu, {
      menus: $props.menus,
      onMenuClick: _cache[0] || (_cache[0] = page => this.$emit('menuClick', page))
    }, null, 8, ["menus"])]),
    _: 1
  }, 8, ["default-active", "collapse"]);
}