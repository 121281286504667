export default {
  name: "layout-header",
  props: ['collapse'],
  data() {
    return {};
  },
  methods: {
    doCollapse() {
      this.$emit('collapseChange');
    }
  }
};